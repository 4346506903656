<template>
  <div class="article-box">
    <p class="leftP">
      <span @click="$router.go(-1)" style="color: #409EFF;cursor: pointer;">学校列表</span> / <span>年级列表</span>
    </p>
    <el-button class="button" type="primary" @click="addLevel()">添加年级</el-button>
    <el-table :data="levelData" style="width: 100%" v-loading="loading">
      <el-table-column prop="ID" label="年级ID" align="center"></el-table-column>
      <el-table-column prop="TypeName" label="年级名称" align="center"></el-table-column>
      <el-table-column label="操作" align="center" width="200">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="seachClass(scope.row)">查看班级</el-button>
          <el-button type="primary" size="mini" @click="seachStudent(scope.row)">查看学生</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="添加年级" :visible.sync="dialogFormVisible" width="500px">
      <el-form :model="form" :inline="true">
        <el-form-item label="年级名称：">
          <el-input v-model="form.typeName" style="width: 200px;"></el-input>
        </el-form-item>
        <el-form-item label="年级级别：">
          <el-select v-model="form.typeLevel" placeholder="请选择年级级别" style="width: 200px;">
            <el-option v-for="item in classTypeLevelData" :key="item.id" :label="item.level" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="add()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {
    classTypeList,
    classTypeAdd,
    classTypeLevel
  } from '@/api/adminApi.js'
  export default {
    data() {
      return {
        schoolId: this.$route.params.schoolId, //学校id
        schoolTypeId: this.$route.params.schoolTypeId, //学校类型id
        levelData: [], // 年级列表
        classTypeLevelData: [], //年级级别信息列表
        form: {}, //添加年级表单
        loading: false, //加载框
        dialogFormVisible: false //添加年级弹框
      }
    },
    mounted() {
      this.levelList() // 获取年级列表
      this.classTypeLevel() //获取年级级别信息列表
    },
    methods: {
      addLevel() { //点击添加年级按钮
        this.form = {}
        this.dialogFormVisible = true
      },
      seachClass(item) { //点击查看班级按钮
        this.$router.push({
          name: "classList",
          params: {
            levelId: item.ID,
            schoolId: this.schoolId
          }
        })
      },
      seachStudent(item) { //点击查看学生按钮
        this.$router.push({
          name: "studentList",
          params: {
            schoolId: this.schoolId,
            levelId: item.ID,
            classId: 0
          }
        })
      },
      add() { //添加年级弹框添加按钮
        if (!this.form.typeName) {
          this.$message.warning("年级名称不能为空")
        } else if (!this.form.typeLevel) {
          this.$message.warning("年级级别不能为空")
        } else {
          const data = {
            school: this.schoolId,
            typeName: this.form.typeName,
            typeLevel: this.form.typeLevel
          }
          classTypeAdd(data).then(res => { //发送添加年级请求
            if (res.status.code == 1) {
              this.$message.success("年级添加成功！")
              this.dialogFormVisible = false //关闭添加年级弹框
              this.levelList() //刷新年级列表
            } else {
              this.$message.error(res.status.msg)
            }
          }).catch(err => {
            console.log(err)
            this.$message.error("发生未知错误，请再试一次！")
          })
        }
      },
      classTypeLevel() { //年级级别列表
        classTypeLevel().then(res => {
          this.classTypeLevelData = res.data.filter(item => item.schoolTypeId == this.schoolTypeId) // 年级级别列表
        }).catch(err => {
          console.log(err)
        })
      },
      levelList() { // 年级列表
        this.loading = true
        const data = {
          school: this.schoolId
        }
        classTypeList(data).then(res => {
          this.levelData = res.data // 年级列表
          this.loading = false
        }).catch(err => {
          console.log(err)
        })
      }
    }
  }
</script>

<style scoped>
  .leftP {
    float: left;
    text-align: left;
    margin-left: 20px;
    font-size: 20px;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 5vh;
    display: inline-block;
    color: gray;
  }

  .button {
    float: right;
    margin-right: 2vw;
  }
</style>
